<template>
  <v-container>
    <h3 style="padding-bottom: 20px">团队邀请</h3>
    <h4 style="padding-bottom: 20px">
      当前用户：<span style="color: blue">{{ this.username }}</span> 可用额度:
      <span style="color: blue">{{
        (this.user_life_limit - this.user_life_used).toFixed(2)
      }}</span>
    </h4>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->

    <v-dialog v-model="genAlert" width="40%">
      <v-card>
        <v-card-title class="text-white"
          >发生错误 <br />
          {{ errmsg }}</v-card-title
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="dlgModifyLimit" width="40%">
      <v-card>
        <v-card-title class="text-white">修改额度</v-card-title>
        <v-form ref="formMod">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-select
                  :items="['增加', '减少']"
                  v-model="dlgModifyLimit_DIR"
                ></v-select> </v-col
              ><v-col cols="12" md="3">
                <v-text-field
                  v-model="dlgModifyLimit_LimitAmount"
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="3"
                ><v-text-field
                  label="2FA"
                  :rules="nameRules"
                  v-model="dlgModifyLimit_fa2"
                ></v-text-field> </v-col></v-row
            ><v-row>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="realModifyLimit()">修改</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-alert
                  v-model="dlgModifyTips.shown"
                  dense
                  text
                  :type="dlgModifyTips.status"
                  >{{ dlgModifyTips.text }}</v-alert
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dlgModifyInvite" width="40%">
      <v-card>
        <v-card-title class="text-white">修改邀请码（会清除密码）</v-card-title>
        <v-form ref="formModInvite">
          <v-container>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="dlgModifyInvite_team"
                  disabled
                ></v-text-field
              ></v-col>
              <v-col cols="12" md="3">
                <v-text-field v-model="dlgModifyInvite_text"></v-text-field
              ></v-col>
              <v-col cols="12" md="3"
                ><v-text-field
                  label="2FA"
                  :rules="nameRules"
                  v-model="dlgModifyInvite_fa2"
                ></v-text-field> </v-col></v-row
            ><v-row>
              <v-col cols="12" md="3">
                <v-btn color="primary" @click="realModifyInvite()">修改</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-alert
                  v-model="dlgModifyTips.shown"
                  dense
                  text
                  :type="dlgModifyTips.status"
                  >{{ dlgModifyTips.text }}</v-alert
                ></v-col
              >
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-card>
      <v-form ref="form">
        <v-container>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="team"
                :rules="nameRules"
                :counter="10"
                label="团队名"
                required
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="invite"
                :rules="nameRules"
                :counter="10"
                label="邀请码"
                hide-details
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3">
              <v-text-field
                v-model="life_limit"
                :rules="NumRules"
                label="总限额"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="day_limit"
                :rules="NumRules"
                label="单日限额"
                hide-details
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3">
              <v-switch label="允许成员邀请" inset v-model="has_son"></v-switch>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field
                v-model="max_use_time"
                :rules="NumRules"
                :counter="10"
                label="允许人数"
                hide-details
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                v-model="fa2"
                :rules="NumRules"
                :counter="10"
                label="2FA验证"
                hide-details
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn color="primary" @click="doCreateItem"
                >新增团队邀请码</v-btn
              ></v-col
            >
          </v-row>
        </v-container>
      </v-form>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="inviteList"
      :options.sync="options"
      :server-items-length="totalInvite"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [5, 25, 50, 100],
        'items-per-page-text': '每页展示',
      }"
    >
      <template v-slot:[`item.invite_code`]="{ item }">
        <span style="float: left; border: 1px; width: 75px">{{
          item.invite_code
        }}</span
        ><v-btn
          x-small
          class="mx-2"
          fab
          dark
          color="cyan"
          @click="doModifyInvite(item)"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.has_son`]="{ item }">
        {{ getSonDesc(item.has_son) }}
      </template>
      <template v-slot:[`item.life_remain`]="{ item }">
        <span style="float: left; border: 1px; width: 75px">{{
          item.life_remain
        }}</span
        ><v-btn
          x-small
          class="mx-2"
          fab
          dark
          color="cyan"
          @click="doModifyLimit(item, 'remain')"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.day_limit`]="{ item }">
        <span style="float: left; border: 1px; width: 75px">{{
          item.day_limit
        }}</span
        ><v-btn
          x-small
          class="mx-2"
          fab
          dark
          color="cyan"
          @click="doModifyLimit(item, 'day')"
        >
          <v-icon dark> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.enabled`]="{ item }">
        <v-switch
          style="width: 10px"
          v-model="item.enabled"
          @click="doEnabledChange(item)"
        ></v-switch>
      </template>
    </v-data-table>
  </v-container>
</template>
 

 
<script>
import axios from "axios";

export default {
  name: "vTeam",

  data: () => ({
    dialog: false,
    genAlert: false,
    errmsg: "发生未知错误",
    username: "",
    user_life_limit: 0,
    user_life_used: 0,

    //dlg 额度修改
    mod_type: "remain", //remain 修改余额 day 修改天额度
    dlgModifyLimit: false,
    dlgModifyLimit_team: "",
    dlgModifyLimit_DIR: "增加",
    dlgModifyLimit_LimitAmount: 100,
    dlgModifyLimit_fa2: "",
    dlgModifyTips: {
      shown: false,
      text: "",
      status: "success",
      lasttime: 0,
      keeptime: 2000,
      timer: null,
    },
    //dlg 修改邀请码
    dlgModifyInvite: false,
    dlgModifyInvite_team: "",
    dlgModifyInvite_text: "",
    dlgModifyInvite_fa2: "",

    //forms
    team: "",
    invite: "",
    life_limit: 100000,
    day_limit: 10000,
    max_use_time: 1,
    has_son: 1,
    fa2: "",

    nameRules: [
      (value) => {
        if (value) return true;

        return "输入不能为空";
      },
      (value) => {
        if (value?.length >= 2 && value?.length < 64) return true;

        return "大于5个字符";
      },
    ],
    NumRules: [
      (value) => {
        if (value > 0 && value < 2147483647) return true;

        return "必须大于0并在合理范围";
      },
    ],

    //data table
    //
    headers: [
      { text: "团队", value: "team" },
      { text: "邀请码", value: "invite_code" },
      { text: "允许人数", value: "use_stat" },
      { text: "总余额", value: "life_remain" },
      { text: "已消耗", value: "life_used" },
      { text: "日限额", value: "day_limit" },
      { text: "邀请下级", value: "has_son" },
      { text: "可用", value: "enabled" },
    ],

    inviteList: [],
    inviteMap: new Map(),
    totalInvite: 0,
    loading: true,
    options: { itemsPerPage: 50, page: 1 },
  }),

  methods: {
    clearme: function () {
      this.url = "";
      this.uname = "";
    },

    loadId() {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/me";
      axios
        .get(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              this.username = svr_data.data.username;
              this.user_life_limit = svr_data.data.life_limit;
              this.user_life_used = svr_data.data.life_used;
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    loadTeam() {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_team_list";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadTeam]", svr_data.data);

              this.realLoadTeam(svr_data.data);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    doCreateItem: function () {
      let valid = this.$refs.form.validate();
      console.log("doCreateItem begin valid = ", valid);
      if (!valid) {
        return;
      }

      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/create_invite";
      let pjData = {
        team: this.team,
        invite: this.invite,
        life_limit: this.life_limit,
        day_limit: this.day_limit,
        max_use_time: this.max_use_time,
        has_son: this.has_son,
        fa2: this.fa2,
      };
      let that = this;
      axios
        .post(url, pjData)
        .then((response) => {
          console.log("doCreateItem get reponse ", response);
          if (response.status == 200) {
            console.log("doCreateItem response.data=200 => ", response.data);
            let svr_data = response.data;
            if (svr_data.code == 200) {
              that.loading = true;
              that.loadTeam();
            } else {
              that.genAlert = true;
              that.errmsg = "团队名/邀请码设置错误";
              if (response.data.msg == "2fa_fail") {
                that.errmsg = "2FA验证码错误，请检查重试";
              } else if (
                response.data.msg == "no_son" ||
                response.data.msg == "no_permit"
              ) {
                that.errmsg = "无邀请权限";
              } else if (
                response.data.msg == "update_limit_fail" ||
                response.data.msg == "update_limit_fail" ||
                response.data.msg == "no_sufficent_limit"
              ) {
                that.errmsg = "无足够额度";
              } else if (response.data.msg == "disable") {
                that.errmsg = "当前团队权限被暂停";
              }
            }
          } else {
            that.genAlert = true;
            that.errmsg = "团队名/邀请码可能设置错误";
            console.log("doCreateItem not update.");
          }
        })
        .catch((err) => {
          console.log("doCreateItem fire err ", err);
        });
    },

    getSonDesc: function (has_son) {
      if (has_son == 1) return "可以";
      else return "不可";
    },

    realLoadTeam: function (data_list) {
      this.inviteMap = new Map();
      for (const item of data_list) {
        let candiItem = {};
        candiItem.team = item.team;
        candiItem.invite_code = item.invite_code;
        candiItem.use_stat = item.use_time + "/" + item.max_use_time;
        candiItem.life_limit = item.life_limit;
        candiItem.life_used = item.money_used;
        candiItem.life_remain = (item.life_limit - item.money_used).toFixed(2);
        candiItem.day_limit = item.day_limit;
        candiItem.has_son = item.has_son;
        candiItem.enabled = item.enabled;

        this.inviteList.push(candiItem);

        this.inviteMap.set(candiItem.team, item);
      }
      this.loading = false;
      this.totalInvite = data_list.length;
    },
    doEnabledChange(rowItem) {
      console.log("doEnableChanged", rowItem);
      console.log("doEnableChanged enabled => ", rowItem.enabled);
      let pData = {
        team: rowItem.team,
        enabled: rowItem.enabled ? 1 : 0,
      };
      this._helpdoHttp(
        "/api/edit_invite",
        pData,
        "post",
        (resp) => {
          if (resp.status == 200 && resp.data.code == 200) {
            console.log("doEnabledChange OK " + resp.data.msg);
          } else {
            console.log("doEnabledChange fail " + resp.data.msg);
            rowItem.enabled = !rowItem.enabled;
            if (resp.data.code == 303) {
              window.location.href = resp.data.data;
            }
          }
        },
        (err) => {
          console.log("err " + err);
          rowItem.enabled = !rowItem.enabled;
        }
      );
    },

    doModifyInvite: function (item) {
      console.log("ModifyInvite item=>", item);
      this.dlgModifyInvite = true;
      this.dlgModifyInvite_team = item.team;
    },

    doModifyLimit: function (item, mod_type) {
      this.mod_type = mod_type;
      console.log("doModifyLimit item=>", item);
      this.dlgModifyLimit = true;
      this.dlgModifyLimit_team = item.team;
    },

    realModifyInvite: function () {
      let valid = this.$refs.formModInvite.validate();
      if (!valid) return;
      console.log("this.dlgModifyInvite_team=>", this.dlgModifyInvite_team);

      let pData = {
        team: this.dlgModifyInvite_team,
        invite: this.dlgModifyInvite_text,
        fa2: this.dlgModifyInvite_fa2,
      };
      let that = this;
      this._helpdoHttp(
        "/api/edit_invite",
        pData,
        "post",
        (resp) => {
          if (resp.status == 200 && resp.data.code == 200) {
            console.log("realdlgModifyInvite OK " + resp.data.msg);
            that.dlgModifyTips.shown = true;
            that.dlgModifyTips.status = "success";
            that.dlgModifyTips.text = "修改成功";
            that.dlgModifyInvite = false;
            that.dlgModifyTips.timer = setInterval(that.showTipsDelay, 500);

            this.loadTeam();
            this.loadId();
          } else {
            console.log("realModifyInvite fail " + resp.data.msg);
            if (resp.data.code == 303) {
              window.location.href = resp.data.data;
            } else {
              that.dlgModifyTips.shown = true;
              that.dlgModifyTips.status = "warning";
              that.dlgModifyTips.text = "修改失败";
              if (resp.data.msg == "2fa_fail")
                that.dlgModifyTips.text = "2FA验证失败";
              that.dlgModifyTips.timer = setInterval(that.showTipsDelay, 500);
            }
          }
        },
        (err) => {
          console.log("err " + err);
        }
      );
    },

    realModifyLimit: function () {
      let valid = this.$refs.formMod.validate();
      if (!valid) return;
      console.log(
        "this.dlgModifyLimit_team=>",
        this.dlgModifyLimit_team,
        this.dlgModifyLimit_LimitAmount,
        this.dlgModifyLimit_DIR
      );
      console.log("this.dlgModifyLimit=>", this.dlgModifyLimit_LimitAmount);
      let amount = Math.abs(parseFloat(this.dlgModifyLimit_LimitAmount));
      let true_amount = 0;
      if (this.dlgModifyLimit_DIR == "增加")
        true_amount = Math.abs(parseFloat(this.dlgModifyLimit_LimitAmount));
      else
        true_amount =
          Math.abs(parseFloat(this.dlgModifyLimit_LimitAmount)) * -1;
      if (amount < 0.01) {
        return;
      }
      let pData = {};
      if (this.mod_type == "remain")
        pData = {
          team: this.dlgModifyLimit_team,
          life_limit: true_amount,
          fa2: this.dlgModifyLimit_fa2,
        };
      else
        pData = {
          team: this.dlgModifyLimit_team,
          day_limit: true_amount,
          fa2: this.dlgModifyLimit_fa2,
        };
      let that = this;
      this._helpdoHttp(
        "/api/edit_invite",
        pData,
        "post",
        (resp) => {
          if (resp.status == 200 && resp.data.code == 200) {
            console.log("realModifyLimit OK " + resp.data.msg);
            that.dlgModifyTips.shown = true;
            that.dlgModifyTips.status = "success";
            that.dlgModifyTips.text = "调账成功";
            that.dlgModifyLimit = false;
            that.dlgModifyTips.timer = setInterval(that.showTipsDelay, 500);

            this.loadTeam();
            this.loadId();
          } else {
            console.log("realModifyLimit fail " + resp.data.msg);
            if (resp.data.code == 303) {
              window.location.href = resp.data.data;
            } else {
              that.dlgModifyTips.shown = true;
              that.dlgModifyTips.status = "warning";
              that.dlgModifyTips.text = "调账失败";
              if (resp.data.msg == "2fa_fail")
                that.dlgModifyTips.text = "2FA验证失败";
              that.dlgModifyTips.timer = setInterval(that.showTipsDelay, 500);
            }
          }
        },
        (err) => {
          console.log("err " + err);
        }
      );
    },

    showTipsDelay: function () {
      this.dlgModifyTips.lasttime += 500;
      if (this.dlgModifyTips.lasttime >= this.dlgModifyTips.keeptime) {
        clearInterval(this.dlgModifyTips.timer);
        this.dlgModifyTips = this.dlgModifyTipsInit();
        this.dlgModifyLimit_fa2 = "";
      }
    },

    dlgModifyTipsInit: function () {
      return {
        shown: false,
        text: "",
        status: "success",
        lasttime: 0,
        keeptime: 2000,
        timer: null,
      };
    },

    _helpdoHttp(url, data, method, callback, excep_handle) {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      //let url = "/api/create_invite";
      let pjData = data;
      //let _submit = axios.post;
      if (method == "post") {
        axios
          .post(url, pjData)
          .then((response) => {
            callback(response);
          })
          .catch((err) => {
            console.log("error " + err);
            excep_handle(err);
          });
      } else if (method == "get") {
        axios
          .get(url, pjData)
          .then((response) => {
            callback(response);
          })
          .catch((err) => {
            console.log("error " + err);
            excep_handle(err);
          });
      }
    },
  },
  mounted: function () {
    let alt = 2;
    if (alt == 1) return;
  },

  async created() {
    this.loadId();
    this.loadTeam();
  },

  watch: {},
};
</script>

