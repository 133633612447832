<template>
  <v-container>
    <h3 style="padding-bottom: 20px">首页 <a @click="logout">退出</a></h3>
    
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->

    <v-row>
      <v-col cols="12" md="12">
        <v-card>
          <v-card-title> 用户信息 </v-card-title>
          <v-card-text> <h4>用户名: {{ this.username }} </h4> </v-card-text>
          <v-card-text> <h4>可用额度: <span style="color:blue">{{ (this.user_life_limit - this.user_life_used).toFixed(2) }}  </span> <span style="width:80px;"></span> <a style=" padding: 4px; font-weight: bolder; " @click="jmpPage('transfer')">&nbsp;代付&nbsp;</a></h4>  </v-card-text>
          <v-card-text> <h4>验证器:  
          <span v-if="fa2enabled" > <v-chip color="green"><div style="font-weight: normal;">已绑定</div></v-chip></span>  <span v-if="!fa2enabled" > <a style=" padding: 4px; font-weight: bolder;  " @click="doFa2" >去绑定 </a></span> </h4> </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  
  <v-dialog v-model="fa2Show" width="40%">
      <v-card>
        <v-card-title class="text-white">扫码绑定2FA</v-card-title>
        <v-form ref="form2fa">
          <v-container>
            <v-row>
              <v-col cols="12" md="12"
                ><img :src="fa2Img" style="width: 80%"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"
                ><v-text-field
                  v-model="fa2_apply"
                  :rules="nameRules"
                  @click="alert2faShown=false"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3"
                ><v-btn @click="doBind2Fa">验证绑定</v-btn></v-col
              ><v-col>
                <v-chip v-if="alert2faShown" variant="flat" :color="alert2faColor">{{
                  alert2faTitle
                }}</v-chip>
              </v-col></v-row
            >
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>  
    
  </v-container>


</template>
 

 
<script>
import axios from "axios";

export default {
  name: "vIndex",

  data: () => ({
    dialog: false,
    genAlert: false,
    errmsg: "发生未知错误",
    username: "",
    user_life_limit: 0,
    user_life_used: 0,
    //绑定2fa需要的
    
    fa2_apply: "",
    fa2enabled: false,
    fa2Show: false,
    fa2Img: "",
    alert2faShown: false,
    alert2faType: "success",
    alert2faTitle: "2FA验证器绑定成功",
    alert2faColor: 'green',
    tips_options: { timer: "", msg: "", last_time: 0, keeptime: 1000 },
    //forms
    team: "",
    invite: "",
    life_limit: 100000,
    day_limit: 10000,
    max_use_time: 1,
    has_son: 1,


    nameRules: [
      (value) => {
        if (value) return true;

        return "输入不能为空";
      },
      (value) => {
        if (value?.length >= 2 && value?.length < 64) return true;

        return "大于5个字符";
      },
    ],
    NumRules: [
      (value) => {
        if (value > 0 && value < 2147483647) return true;

        return "必须大于0并在合理范围";
      },
    ],

    //data table
    //
    headers: [
      { text: "团队", value: "team" },
      { text: "邀请码", value: "invite_code" },
      { text: "允许人数", value: "use_stat" },
      { text: "总限额", value: "life_limit" },
      { text: "日限额", value: "day_limit" },
      { text: "邀请下级", value: "has_son", width: "120px" },
    ],

    inviteList: [],
    inviteMap: new Map(),
    totalInvite: 0,
    loading: true,
    options: { itemsPerPage: 50, page: 1 },
  }),

  methods: {
    clearme: function () {
      this.url = "";
      this.uname = "";
    },
    logout(){
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/logout";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 200) {
              console.log("logout data got ", svr_data.data);
              window.location.href = svr_data.data;
            }  else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    loadId() {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/me";
      axios
        .get(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              this.username = svr_data.data.username;
              this.user_life_limit = svr_data.data.life_limit;
              this.user_life_used = svr_data.data.life_used;
              this.fa2enabled = svr_data.data.fa2enabled;
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    loadTeam() {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_team_list";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadTeam]", svr_data.data);

              this.realLoadTeam(svr_data.data);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    doCreateItem: function () {
      let valid = this.$refs.form.validate();
      console.log("doCreateItem begin valid = ", valid);
      if (!valid) {
        return;
      }

      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/create_invite";
      let pjData = {
        team: this.team,
        invite: this.invite,
        life_limit: this.life_limit,
        day_limit: this.day_limit,
        max_use_time: this.max_use_time,
        has_son: this.has_son,
      };
      let that = this;
      axios
        .post(url, pjData)
        .then((response) => {
          console.log("doCreateItem get reponse ", response);
          if (response.status == 200) {
            console.log("doCreateItem response.data=200 => ", response.data);
            let svr_data = response.data;
            if (svr_data.code == 200) {
              that.loading = true;
              that.loadTeam();
            } else {
              that.genAlert = true;
              that.errmsg = "团队名/邀请码设置错误";
            }
          } else {
            that.genAlert = true;
            that.errmsg = "团队名/邀请码设置错误";
            console.log("doCreateItem not update.");
          }
        })
        .catch((err) => {
          console.log("doCreateItem fire err ", err);
        });
    },

    getSonDesc: function (has_son) {
      if (has_son == 1) return "可以";
      else return "不可";
    },

    realLoadTeam: function (data_list) {
      this.inviteMap = new Map();
      for (const item of data_list) {
        let candiItem = {};
        candiItem.team = item.team;
        candiItem.invite_code = item.invite_code;
        candiItem.use_stat = item.use_time + "/" + item.max_use_time;
        candiItem.life_limit = item.life_limit;
        candiItem.day_limit = item.day_limit;
        candiItem.has_son = item.has_son;

        this.inviteList.push(candiItem);

        this.inviteMap.set(candiItem.team, item);
      }
      this.loading = false;
      this.totalInvite = data_list.length;
    },

    jmpPage: function (path, acckey) {
      if ( path == 'transfer'){
        this.$router.push({ name: "vTransfer", params: { acckey: acckey } });
      } 
      if ( path == 'team'){
        this.$router.push({ name: "vTeam", params: { acckey: acckey } });
      } 
      if ( path == 'bills'){
        this.$router.push({ name: "vBills", params: { acckey: acckey } });
      } 
      if ( path == 'indexPage'){
        this.$router.push({ name: "vIndex", params: { acckey: acckey } });
      } 
      
    },
    doFa2: function () {
      console.log("Do 2FA");
      this.Load2FAImg();
    },

    Load2FAImg: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/fa2get";
      let that = this;
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[fa2get] -> ", svr_data.data);
              that.fa2Show = true;
              that.fa2Img = svr_data.data.fa2;
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    doBind2Fa: function () {
      let valid = this.$refs.form2fa.validate();
      if (!valid) {
        return;
      }
      this.realBind2FA();
    },
    checkTips: function () {
      this.tips_options.last_time += 500;
      if (this.tips_options.last_time >= this.tips_options.keeptime) {
        clearInterval(this.tips_options.timer);
        this.tips_options.timer = "";
        this.tips_options.last_time = 0;
        this.fa2Show = false; //关闭fa2设置的box
        this.set2FaBindStatus(2); //关闭tips
      }
    },
    set2FaBindStatus: function (r) {
      //0 set OK
      //1 set FAIL
      //2 set CLOSE
      if (r == 0) {
        this.alert2faType = "success";
        this.alert2faTitle = "2FA验证器绑定成功";
        this.alert2faColor = 'green';
        this.alert2faShown = true;
        console.log("set2FaBindStatus set ok");
      } else if (r == 1) {
        this.alert2faType = "warning";
        this.alert2faTitle = "2FA验证器绑定失败，请检查重试";
        this.alert2faColor = 'red';
        this.alert2faShown = true;
        console.log("set2FaBindStatus set failed.");
      } else {
        this.alert2faShown = false;
      }
    },
    realBind2FA: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/fa2check/" + this.fa2_apply.trim();
      let that = this;
      axios
        .post(url)
        .then((response) => {
          console.log("realBind2FA get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              //绑定成功
              console.log("[realBind2FA] -> ", svr_data.data);
              that.tips_options.timer = setInterval(that.checkTips, 500);
              that.set2FaBindStatus(0);
            } else {
              //绑定失败
              that.set2FaBindStatus(1);
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
            that.set2FaBindStatus(1);
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

  },
  mounted: function () {
    let alt = 2;
    if (alt == 1) return;
  },

  async created() {
    this.loadId();
    this.loadTeam();
    //console.log('params', this.$route.params);
  },

  watch: {},
};
</script>

