<template>
  <v-container>
    <h3 style="padding-bottom: 8px">交易</h3>
    <h4 style="padding-bottom: 8px">
      当前用户：<span style="color: blue">{{ this.username }}</span> 可用额度:
      <span style="color: blue">{{
        (this.user_life_limit - this.user_life_used).toFixed(2)
      }}</span>
    </h4>

    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->

    <v-dialog v-model="transferAlert" width="40%">
      <v-card>
        <v-card-title class="text-white"
          >发生错误 <br />{{ transferFailMsg }}</v-card-title
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="fa2Show" width="40%">
      <v-card>
        <v-card-title class="text-white">扫码绑定2FA</v-card-title>
        <v-form ref="form2fa">
          <v-container>
            <v-row>
              <v-col cols="12" md="12"
                ><img :src="fa2Img" style="width: 80%"
              /></v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3"
                ><v-text-field
                  v-model="fa2_apply"
                  :rules="nameRules"
                  @click="alert2faShown = false"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3"
                ><v-btn @click="doBind2Fa">验证绑定</v-btn></v-col
              ><v-col>
                <v-chip
                  v-if="alert2faShown"
                  variant="flat"
                  :color="alert2faColor"
                  >{{ alert2faTitle }}</v-chip
                >
              </v-col></v-row
            >
          </v-container>
        </v-form>
      </v-card>
    </v-dialog>

    <v-row dense>
      <v-col>
        <v-card>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="account_name"
                    :rules="nameRules"
                    :counter="10"
                    label="账号名"
                    required
                    hide-details
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="bank_num"
                    :rules="nameRules"
                    :counter="10"
                    label="银行账号"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-combobox
                    v-model="bank_name"
                    label="开户银行"
                    :items="bank_items"
                    required
                  ></v-combobox>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="amount"
                    :rules="NumRules"
                    :counter="10"
                    label="金额"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field
                    v-model="fa2"
                    :rules="NumRules"
                    :counter="10"
                    label="2FA验证"
                    hide-details
                    required
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn v-if="fa2enabled == 0" @click="doFa2">绑定2FA</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="1">
                  <v-btn
                    color="primary"
                    :loading="transfering"
                    @click="doTransfer"
                    >转账</v-btn
                  ></v-col
                >
                <v-col cols="12" md="3">
                  <v-alert
                    v-model="transferResultTips.shown"
                    dense
                    text
                    :type="transferResultTips.status"
                    >{{ transferResultTips.text }}</v-alert
                  ></v-col
                >
              </v-row></v-container
            >
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="billList"
          :options.sync="options"
          :server-items-length="totalBill"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 25, 50, 100],
            'items-per-page-text': '每页展示',
          }"
        >
          <template v-slot:[`item.status`]="{ item }">
            {{ getStatusDesc(item.status) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>
 

 
<script>
import axios from "axios";

export default {
  name: "vTransfer",

  data: () => ({
    xsid: "0",
    dialog: false,
    //转账失败
    transferFailMsg: "发起支付失败 可能是可用额度不足",
    transferAlert: false,
    transfering: false,

    transferResultTips: {
      shown: false,
      text: "",
      status: "success",
      lasttime: 0,
      keeptime: 2000,
      timer: null,
    },
    //
    alert2faShown: false,
    alert2faType: "success",
    alert2faTitle: "2FA验证器绑定成功",
    //2FA
    //转账使用的2FA
    fa2: "",
    //绑定使用的2FA
    alert2faColor: "green",
    fa2_apply: "",
    fa2enabled: false,
    fa2Show: false,
    fa2Img: "",
    //system user.
    username: "",
    user_life_limit: 0,
    user_life_used: 0,
    //
    bank_items: [],
    bank_item_map: {},
    bank_code: "",
    bank_name: "",
    account_name: "",
    bank_num: "",
    amount: "",
    //
    tips_options: { timer: "", msg: "", last_time: 0, keeptime: 1000 },

    nameRules: [
      (value) => {
        if (value) return true;

        return "输入不能为空";
      },
      (value) => {
        if (value?.length >= 2 && value?.length < 64) return true;

        return "大于5个字符";
      },
    ],
    NumRules: [
      (value) => {
        if (value > 0 && value < 2147483647) return true;

        return "必须大于0并在合理范围";
      },
    ],

    //data tables
    //data table
    //
    headers: [
      { text: "时间", value: "ctime" },
      { text: "ID", value: "trans_id" },
      { text: "账户户名", value: "account_name" },
      { text: "银行账号", value: "bank_num" },
      { text: "金额", value: "amount" },
      { text: "状态", value: "status" },
      { text: "团队", value: "belong_team" },
      { text: "操作人", value: "username" },
    ],

    billList: [],
    billMap: new Map(),
    totalBill: 0,
    loading: false,
    options: { itemsPerPage: 50, page: 1 },
  }),

  methods: {
    clearme: function () {
      this.url = "";
      this.uname = "";
    },

    loadId() {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/me";
      axios
        .get(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              this.username = svr_data.data.username;
              this.user_life_limit = svr_data.data.life_limit;
              this.user_life_used = svr_data.data.life_used;
              this.fa2enabled = svr_data.data.fa2enabled == 0 ? false : true;
              console.log("Fa2 enable", this.fa2enabled);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    doTransfer: function () {
      console.log("doTransfer xxid ", this.xsid);
      let valid = this.$refs.form.validate();
      console.log("doTransfer begin valid = ", valid);
      if (!valid) {
        return;
      }
      this.transfering = true;
      try {
        this.doCreateTransfer();
      } catch (error) {
        console.log("dostransfer error=>", error);
      } finally {
        this.transfering = false;
      }
    },

    doCreateTransfer: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/create_transfer";
      this.bank_code = this.bank_item_map[this.bank_name];
      let pjData = {
        amount: this.amount,
        bank_num: this.bank_num,
        account_name: this.account_name,
        bank_name: this.bank_name,
        bank_code: this.bank_code,
        xsid: this.xsid,
        fa2: this.fa2,
      };
      let that = this;
      axios
        .post(url, pjData)
        .then((response) => {
          console.log("doCreateTransfer get reponse ", response);
          if (response.status == 200 && response.data.code == 200) {
            console.log(
              "doCreateTransfer response.data=200 => ",
              response.data
            );
            //成功转账
            that.transferResultTips.shown = true;
            that.transferResultTips.status = "success";
            that.transferResultTips.text = "转账成功";
            that.transferResultTips.timer = setInterval(
              that.showTipsDelay,
              500
            );
            this.refreshToken();
            that.loading = true;
            that.loadBills();
            this.loadId();
            that.loading = false;
          } else {
            console.log(
              "doCreateTransfer not update. => (response) ",
              response
            );
            that.transferResultTips.shown = true;
            that.transferResultTips.status = "warning";
            that.transferResultTips.text = "转账失败";
            //this.transferAlert = true;
            this.transferFailMsg = "发起支付失败 可能是可用额度不足";
            that.transferResultTips.text = "发起支付失败 可能是可用额度不足";
            if (response.data.msg == "2fa_fail") {
              this.transferFailMsg = "2FA验证码失败";
              that.transferResultTips.text = "2FA验证码失败";
            } else if (response.data.code == 303) {
              this.transferFailMsg = "登陆失效，请刷新重新登录";
              that.transferResultTips.text = "登陆失效，请刷新重新登录";
            }
            that.transferResultTips.timer = setInterval(
              that.showTipsDelay,
              500
            );
          }
        })
        .catch((err) => {
          console.log("doCreateTransfer fire err ", err);
        });
    },

    showTipsDelay: function () {
      this.transferResultTips.lasttime += 500;
      if (
        this.transferResultTips.lasttime >= this.transferResultTips.keeptime
      ) {
        clearInterval(this.transferResultTips.timer);
        this.transferResultTips = this.transferResultTipsInit();
      }
    },

    //
    // LoadBills
    //
    loadBills: function () {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      let that = this;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_bill_list";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadBill] -> ", svr_data.data);

              that.realLoadBills(svr_data.data);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    getStatusDesc: function (status) {
      if (status == "INIT") return "已发起";
      else if (status == "SUBMIT") return "支付中";
      else if (status == "OK") return "已成功";
      else if (status == "FAIL") return "已失败";
      else return status;
    },

    realLoadBills: function (data) {
      //console.log("realLoadBills", data);
      this.billList = [];
      for (let item of data) {
        //console.log("=>item", item);
        let di = {};
        di.username = item.username;
        di.account_name = item.account_name;
        di.amount = item.amount;
        di.bank_num = item.bank_num;
        di.belong_team = item.belong_team;
        di.id = item.id;
        di.status = item.status;
        if( item.data_text != null) di.status = '失败:'+item.data_text;
        //di.trans_id = item.trans_id.substr(-6);
        di.trans_id = item.trans_id;
        di.ctime = item.ctime.substr(-20).substr(0, 14).replace("T", " ");
        this.billList.push(di);
      }
      this.totalBill = this.billList.length;
      //this.billList = data ;
    },

    LoadBankItems: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_bank_list";
      let that = this;
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[get_bank_list] -> ", svr_data.data);

              that.realLoadBankItems(svr_data.data);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    realLoadBankItems: function (data) {
      this.bank_items = [];
      this.bank_item_map = new Map();
      for (const item of data) {
        this.bank_items.push(item.bank_name);
        this.bank_item_map[item.bank_name] = item.bank_code;
      }
    },

    refreshToken: function () {
      this.xsid = parseInt(Math.random() * 100000000000);
    },

    Load2FAImg: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/fa2get";
      let that = this;
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[fa2get] -> ", svr_data.data);
              that.fa2Show = true;
              that.fa2Img = svr_data.data.fa2;
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    doBind2Fa: function () {
      let valid = this.$refs.form2fa.validate();
      if (!valid) {
        return;
      }
      this.realBind2FA();
    },
    checkTips: function () {
      this.tips_options.last_time += 500;
      if (this.tips_options.last_time >= this.tips_options.keeptime) {
        clearInterval(this.tips_options.timer);
        this.tips_options.timer = "";
        this.tips_options.last_time = 0;
        this.fa2Show = false; //关闭fa2设置的box
        this.set2FaBindStatus(2); //关闭tips
      }
    },
    set2FaBindStatus: function (r) {
      //0 set OK
      //1 set FAIL
      //2 set CLOSE
      if (r == 0) {
        this.alert2faType = "success";
        this.alert2faTitle = "2FA验证器绑定成功";
        this.alert2faColor = "green";
        this.alert2faShown = true;
        console.log("set2FaBindStatus set ok");
      } else if (r == 1) {
        this.alert2faType = "warning";
        this.alert2faTitle = "2FA验证器绑定失败，请检查重试";
        this.alert2faColor = "red";
        this.alert2faShown = true;
        console.log("set2FaBindStatus set failed.");
      } else {
        this.alert2faShown = false;
      }
    },
    realBind2FA: function () {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/fa2check/" + this.fa2_apply.trim();
      let that = this;
      axios
        .post(url)
        .then((response) => {
          console.log("realBind2FA get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              //绑定成功
              console.log("[realBind2FA] -> ", svr_data.data);
              that.tips_options.timer = setInterval(that.checkTips, 500);
              that.set2FaBindStatus(0);
              that.loadId();
            } else {
              //绑定失败
              that.set2FaBindStatus(1);
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
            that.set2FaBindStatus(1);
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },
    doFa2: function () {
      console.log("Do 2FA");
      this.Load2FAImg();
    },

    transferResultTipsInit: function () {
      return {
        shown: false,
        text: "",
        status: "success",
        lasttime: 0,
        keeptime: 2000,
        timer: null,
      };
    },
  },

  mounted: function () {
    let alt = 2;
    if (alt == 1) return;
  },

  async created() {
    this.xsid = parseInt(Math.random() * 100000000000);
    this.loadId();
    this.loading = true;
    this.loadBills();
    this.loading = false;
    this.LoadBankItems();
  },

  watch: {},
};
</script>

