<template>
  <v-container>
    <h3 style="padding-bottom: 20px">我的账单</h3>
    <!-- 此处的$route.params.msg表示从路由/msg传递过来的参数 -->

    <v-row dense>
      <v-col>
        <v-row no-gutters>
          <v-col>
            <v-menu
              v-model="menu_from"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_from"
                  label="开始日期"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_from"
                locale="zh-cn"
                @input="menu_from = false"
              ></v-date-picker> </v-menu></v-col
          ><v-col>
            <v-menu
              v-model="menu_to"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date_to"
                  label="结束日期"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date_to"
                locale="zh-cn"
                @input="menu_to = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col>
            <v-btn @click="search_ctri" style="margin-left: 8px">查找</v-btn>
            <v-btn @click="search_reset" style="margin-left: 8px">重置</v-btn>
          </v-col>
        </v-row>
        <v-row
          ><v-col style="border: solid 1px">
            <v-simple-table>
              <thead>
                <tr>
                  <th><strong>总笔数 </strong></th>

                  <th><strong>总金额</strong></th>

                  <th><strong>成功</strong></th>

                  <th><strong>已提交</strong></th>

                  <th><strong>已失败</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ stat_count }}</td>
                  <td>
                    {{ stat_amount }}
                  </td>
                  <td>
                    {{ success_amount }}
                  </td>
                  <td>
                    {{ submit_amount }}
                  </td>
                  <td>
                    {{ fail_amount }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-col></v-row
        >
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              :items="billList"
              :options.sync="options"
              :server-items-length="totalBill"
              :loading="loading"
              :footer-props="{
                itemsPerPageOptions: [5, 25, 50, 100],
                'items-per-page-text': '每页展示',
              }"
            >
              <template v-slot:[`item.status`]="{ item }">
                {{ getStatusDesc(item.status) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
 

 
<script>
import axios from "axios";

export default {
  name: "vBills",

  data: () => ({
    dialog: false,
    setAlert: false,

    headers: [
      { text: "时间", value: "ctime" },
      { text: "ID", value: "trans_id" },
      { text: "账户户名", value: "account_name" },
      { text: "银行账号", value: "bank_num" },
      { text: "金额", value: "amount" },
      { text: "状态", value: "status" },
      { text: "团队", value: "belong_team" },
      { text: "操作人", value: "username" },
    ],

    billList: [],
    billMap: new Map(),
    //search
    menu_from: false,
    menu_to: false,
    date_from: "",
    date_to: "",
    //stat for payment
    stat_count: 0,
    stat_amount: 0,
    success_amount: 0,
    fail_amount: 0,
    submit_amount: 0,
    //from_time: '',
    //to_time: '',
    totalBill: 0,
    loading: false,
    options: { itemsPerPage: 50, page: 1 },
  }),

  methods: {
    clearme: function () {
      this.url = "";
      this.uname = "";
    },

    loadData() {
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/me";
      axios
        .get(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    loadBills: function () {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      let that = this;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/get_bill_list";
      axios
        .post(url)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadBill] -> ", svr_data.data);

              that.realLoadBills(svr_data.data);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    getStatusDesc: function (status) {
      if (status == "INIT") return "已发起";
      else if (status == "SUBMIT") return "支付中";
      else if (status == "OK") return "已成功";
      else if (status == "FAIL") return "已失败";
      else return status ;
    },

    realLoadBills: function (data) {
      //console.log("realLoadBills", data);
      this.billList = [];
      for (let item of data) {
        //console.log("=>item", item);
        let di = {};
        di.username = item.username;
        di.account_name = item.account_name;
        di.amount = item.amount;
        di.bank_num = item.bank_num;
        di.belong_team = item.belong_team;
        di.id = item.id;
        di.status = item.status;
        if( item.data_text != null) di.status = '失败:'+item.data_text;
        //di.trans_id = item.trans_id.substr(-6);
        di.trans_id = item.trans_id;
        di.ctime = item.ctime.substr(-20).substr(0, 14).replace("T", " ");
        this.billList.push(di);
      }
      this.totalBill = this.billList.length;
      //this.billList = data ;
    },
    realLoadBills_SVR: function (data) {
      //console.log("realLoadBills", data);
      this.billList = [];
      for (let item of data) {
        //console.log("=>item", item);
        let di = {};
        di.username = item.username;
        di.account_name = item.account_name;
        di.amount = item.amount;
        di.bank_num = item.bank_num;
        di.belong_team = item.belong_team;
        di.id = item.id;
        di.status = item.status;
        if( item.data_text != null) di.status = '失败:'+item.data_text;

        //di.trans_id = item.trans_id.substr(-6);
        di.trans_id = item.trans_id;
        di.ctime = item.ctime.substr(-20).substr(0, 14).replace("T", " ");
        this.billList.push(di);
      }
      //this.totalBill = this.billList.length;
      //this.billList = data ;
    },

    searchData: function () {
      this.loading = true;
      this.inviteList = [];
      this.inviteMap = new Map();
      let that = this;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/search_list";
      let pjData = {
        from_time: this.date_from,
        to_time: this.date_to,
        page_idx: this.options.page - 1,
        page_size: this.options.itemsPerPage,
      };
      axios
        .post(url, pjData)
        .then((response) => {
          console.log("get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              console.log("[loadBill] -> ", svr_data.data);
              that.totalBill = svr_data.data.total;
              that.realLoadBills_SVR(svr_data.data.list);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    statBillTotal: function () {
      let that = this;
      axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
      console.log("baseURL", axios.defaults.baseURL);
      let url = "/api/bill_stat_total";
      let pjData = {
        from_time: this.date_from,
        to_time: this.date_to,
      };
      axios
        .post(url, pjData)
        .then((response) => {
          console.log("bill_stat_total get reponse ", response);
          if (response.status == 200) {
            let svr_data = response.data;
            if (svr_data.code == 303) {
              window.location.href = svr_data.data;
            } else if (svr_data.code == 200) {
              //TODO coding
              that.stat_count = svr_data.data["rc_count"];
              that.stat_amount = parseFloat(
                svr_data.data["sum_amount"]
              ).toFixed(2);
              that.success_amount = parseFloat(
                svr_data.data["success_amount"]
              ).toFixed(2);
              that.fail_amount = parseFloat(
                svr_data.data["fail_amount"]
              ).toFixed(2);
              that.submit_amount = parseFloat(
                svr_data.data["submit_amount"]
              ).toFixed(2);
            } else {
              console.log("data got ", svr_data.data);
            }
          } else {
            console.log("not update.");
          }
        })
        .catch((err) => {
          console.log("fire err ", err);
        });
    },

    search_ctri: function () {
      console.log("search_ctri call");
      this.searchData();
      this.statBillTotal();
    },
    search_reset: function () {
      console.log("search_reset call");
      this.date_from = "";
      this.date_to = "";
      this.search_ctri();
    },

    formatDate: function (date, format) {
      const map = {
        mm: date.getMonth() + 1,
        dd: date.getDate(),
        yy: date.getFullYear().toString().slice(-2),
        yyyy: date.getFullYear(),
      };

      return format.replace(/mm|dd|yy|yyyy/gi, (matched) => map[matched]);
    },
  },
  mounted: function () {
    let alt = 2;
    if (alt == 1) return;
  },

  async created() {
    //this.loadData();
    let days = 7;
    let currentDate = new Date();
    let days7Ago = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000);
    this.date_from = this.formatDate(days7Ago, "20yy-mm-dd");
    console.log("date_from =>", this.date_from);
    this.searchData();
    this.statBillTotal();

    console.log("params", this.$route.path);
  },

  watch: {
    options: {
      handler() {
        console.log("options", this.options);
        console.log("Go to page ", this.options.page);
        console.log("Go to page size ", this.options.itemsPerPage);
        this.searchData();
        this.statBillTotal();
      },
      deep: true,
    },
  },
};
</script>

